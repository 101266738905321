<template>
  <div class="login-container">
    <el-form ref="selected" :model="selected" :rules="rules" class="login-form" auto-complete="on" label-position="left" @submit.native.prevent="sendRecovery('selected')">
      <public-header />
      <div v-if="!sent">
        <h2 class="white-text">Locked out ?</h2>
        <p class="white-text">Send a recovery link to your email </p>
        <el-form-item prop="email" >
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input v-model="selected.email" name="email" type="text" auto-complete="on" placeholder="email" />
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" type="primary" style="width:100%;" @click.native.prevent="sendRecovery('selected')">
            Send
          </el-button>
        </el-form-item>
      </div>
      <div v-else class="center white-text">
        <p>Email sent. Please check your email for the link.</p>
      </div>
      <back-to-login />
    </el-form>
  </div>
</template>

<script>
import { User } from '@/api/user'
import { BackToLogin, PublicHeader } from '../layout/components'

export default {
  name: 'ForgotPasswod',
  components: { BackToLogin, PublicHeader },
  data() {
    return {
      sent: false,
      selected: {
        email: null
      },
      loading: false,
      rules: {
        email: [
          { required: true, type: 'email' }
        ]
      }
    }
  },
  methods: {
    save() {
      User({ email: this.selected.email }, 'send_recovery_link').then(res => {
        this.loading = false
        this.sent = true
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    sendRecovery(formName) {
      var self = this
      this.loading = true
      this.validate(formName, function(valid) {
        if (valid) {
          self.save()
        } else {
          self.loading = false
        }
      })
    },
    validate: function(formName, cb) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate((valid) => {
          cb(valid)
        })
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#2d3a4b;
  $light_gray:#eee;

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: #fff !important;
        }
      }
    }
    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }
  }

</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  $bg:#004009;
  $dark_gray:#889aa4;
  $light_gray:#eee;
  .login-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $bg;
    .login-form {
      position: absolute;
      left: 0;
      right: 0;
      width: 520px;
      max-width: 100%;
      padding: 35px 35px 15px 35px;
      margin: 120px auto;
    }
    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }
    .title {
      font-size: 26px;
      font-weight: 400;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
</style>
